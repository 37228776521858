import produce from 'immer'
import { STATS } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  stats: null,
  loading: {
    fetchStats: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${STATS.SET_STATS}_${PROMISE_STATUS.FULFILLED}`: {
      // state.selectedSurvey = action.payload
      state.stats = action.payload
      state.loading.fetchStats = false
      break
    }
    case `${STATS.SET_STATS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchStats = true
      break
    }
    case `${STATS.SET_STATS}_${PROMISE_STATUS.REJECTED}`: {
      state.stats = null
      state.loading.fetchStats = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
