import EP from '@src/api/endpoints'
import client from '@src/api'

export const getNotes = (participant_id) => {
  return client
    .get(EP.NOTES_PARTICIPANT_ID(participant_id))
    .then((res) => res.data)
}

export const postANote = (participant_id, data) => {
  return client
    .post(EP.NOTES_PARTICIPANT_ID(participant_id), data)
    .then((res) => res.data)
}

export const deleteNote = (noteId) => {
  return client.delete(EP.NOTES_ID(noteId)).then((res) => res.data)
}
