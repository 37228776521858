// https://my.api.mockaroo.com/elfs_users.json?key=772fdd90

import EP from '@src/api/endpoints'
import client from '@src/api'

export const getParticipants = () => {
  return client.get(EP.PARTICIPANTS()).then((res) => res.data)
}

export const postAParticipant = (data) => {
  return client.post(EP.PARTICIPANTS(), data).then((res) => res.data)
}

export const putAParticipant = (participantId, data) => {
  return client
    .put(EP.PARTICIPANTS_ID(participantId), data)
    .then((res) => res.data)
}

export const getParticipantsSurvey = (uuid) => {
  return client.get(EP.PARTICIPANTS_SURVEY(uuid)).then((res) => res.data)
}

export const patchAParticipant = (uuid, data) => {
  return client.patch(EP.PARTICIPANTS_ID(uuid), data).then((res) => res.data)
}
