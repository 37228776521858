import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getResponse,
  getResponses,
  updateResponseNeedsUpdate,
} from '@actions/responses.actions'
import { getQuestions } from '@actions/questions.actions'
import { Drawer, Table, Typography } from 'antd'
import SurveyResponse from '@components/SurveyResponse'
import useDrawer from '@src/hooks/useDrawer'
import moment from 'moment'

const { Title } = Typography

let columns = [
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    filters: [
      {
        text: 'Completed',
        value: 'COMPLETED',
      },
      {
        text: 'Needs Update',
        value: 'NEEDS_UPDATE',
      },
    ],
    onFilter: (value, record) => record.status === value,
  },
  {
    title: 'Submitted On',
    key: 'submittedAt',
    dataIndex: 'submittedAt',
    render: (value) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Height',
    key: 'surveyResponse.height',
    dataIndex: 'surveyResponse.height',
  },
  {
    title: 'Gender',
    key: ['surveyResponse', 'gender'],
    dataIndex: ['surveyResponse', 'gender'],
  },
  {
    title: 'First day of last period',
    key: ['surveyResponse', 'first_day_last_period'],
    dataIndex: ['surveyResponse', 'first_day_last_period'],
    render: (value) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
  },
  {
    title: 'Current Partners gender',
    key: ['surveyResponse', 'current_partner_gender'],
    dataIndex: ['surveyResponse', 'current_partner_gender'],
  },
  {
    title: 'Currently Pregnant',
    key: ['surveyResponse', 'current_pregnant'],
    dataIndex: ['surveyResponse', 'current_pregnant'],
    filters: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    onFilter: (value, record) =>
      record.surveyResponse.current_pregnant === value,
    render: (value) => {
      if (typeof value === 'boolean') {
        if (value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
      return value
    },
  },
]

const SurveyContainer = ({
  getQuestions,
  questions,
  responses,
  getResponses,
  loading,
  updateResponseNeedsUpdate,
  responseLoading,
  getResponse,
  selectedResponse,
}) => {
  const { handleVisibility, drawerVisible } = useDrawer()
  // const [selectedResponse, setSelectedResponse] = useState()

  useEffect(() => {
    getResponses()
    getQuestions()
  }, [getResponses, getQuestions])

  const handleUpdateResponseNeedsUpdate = (surveyUuid, status) => {
    updateResponseNeedsUpdate(surveyUuid, status).then(() => {
      handleVisibility()
      return getResponses()
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <Drawer
        title="Survey Details"
        placement={'right'}
        closable={false}
        onClose={handleVisibility}
        visible={drawerVisible}
        width={700}
      >
        <SurveyResponse
          questions={questions}
          selectedSurvey={selectedResponse}
          loading={responseLoading}
          updateResponseNeedsUpdate={handleUpdateResponseNeedsUpdate}
        />
      </Drawer>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={2}>Survey Details</Title>
      </div>

      <Table
        size={'small'}
        columns={columns}
        dataSource={responses}
        rowKey={'uuid'}
        style={{ cursor: 'pointer' }}
        loading={loading.fetchingResponses}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const { uuid } = record
              handleVisibility()
              getResponse(uuid)
            },
          }
        }}
        rowClassName={({ status }, index) =>
          status === 'INIT'
            ? 'table-row-init'
            : status === 'COMPLETED'
            ? 'table-row-completed'
            : 'table-row-needs-update'
        }
        // onRow={(record) => {
        //   return {
        //     async onClick() {
        //       handleVisibility()
        //       getResponse(record.uuid)
        //       // setSelectedResponse(record)
        //     },
        //   }
        // }}
      />
    </div>
  )
}

const mapStateToProps = ({ responses, questions }) => {
  return {
    responses: responses.responses,
    loading: responses.loading,
    questions: questions.questions,
    responseLoading: responses.loading,
    selectedResponse: responses.selectedResponse,
  }
}

const mapDispatchToProps = {
  getResponses,
  getQuestions,
  updateResponseNeedsUpdate,
  getResponse,
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyContainer)
