import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { fetchFollowUpDetails } from '@actions/survey.actions'
import { Button, Drawer, Table, Tag, Typography } from 'antd'
import { CommentOutlined, EyeOutlined } from '@ant-design/icons'
import NotesContainer from '@containers/participants/notes'
import { getParticipants } from '@actions/participants.actions'
import SurveyResponse from '@components/SurveyResponse'
import useDrawer from '@src/hooks/useDrawer'
import { getResponse } from '@actions/responses.actions'
import { getQuestions } from '@actions/questions.actions'
const { Title } = Typography

const FollowUpComp = ({
  fetchFollowUpDetails,
  followUps,
  getParticipants,
  questions,
  selectedResponse,
  responseLoading,
  getResponse,
  getQuestions,
}) => {
  const [selectedParticipantId, setSelectedParticipantId] = useState()
  const { handleVisibility, drawerVisible } = useDrawer()

  const notesRef = useRef()

  useEffect(() => {
    fetchFollowUpDetails()
    getQuestions()
  }, [])

  const columns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
    },
    {
      key: 'participant_id',
      dataIndex: 'participant_id',
      title: 'Participant ID',
    },
    {
      key: 'semen_analysis',
      dataIndex: 'semen_analysis',
      title: 'Did semen analysis',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
      ],
      onFilter: (value, record) => record.semen_analysis === value,
      render: (value) => (value ? <Tag color={'green'}>Yes</Tag> : '-'),
    },
    {
      key: 'surgery_endometriosis',
      dataIndex: 'surgery_endometriosis',
      title: 'Did Surgery',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
      ],
      onFilter: (value, record) => record.surgery_endometriosis === value,
      render: (value) => (value ? <Tag color={'green'}>Yes</Tag> : '-'),
    },
    {
      key: 'tubal_patency',
      dataIndex: 'tubal_patency',
      title: 'Did tubal patency',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
      ],
      onFilter: (value, record) => record.tubal_patency === value,
      render: (value) => (value ? <Tag color={'green'}>Yes</Tag> : '-'),
    },
    {
      key: 'outcome_recent_pregnancy',
      dataIndex: 'outcome_recent_pregnancy',
      title: 'Is live birth',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
      ],
      onFilter: (value, record) => record.outcome_recent_pregnancy === value,
      render: (value) => (value ? <Tag color={'green'}>Yes</Tag> : '-'),
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created On',
      render: (value) => (value ? new Date(value).toLocaleDateString() : null),
    },
    {
      title: 'Operations',
      render: (form) => {
        return (
          <div>
            <Button
              size={'small'}
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={() => {
                setSelectedParticipantId(form.participant_uuid)
                notesRef.current.handleVisibility(true)
              }}
            >
              <div>
                <CommentOutlined />
              </div>
            </Button>

            <Button
              size={'small'}
              type={'primary'}
              style={{ marginLeft: 5 }}
              onClick={() => {
                handleVisibility()
                getResponse(form.survey_details_uuid)
              }}
            >
              <EyeOutlined />
            </Button>
          </div>
        )
      },
    },
  ]

  if (!followUps) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <NotesContainer
        ref={notesRef}
        participantId={selectedParticipantId}
        getParticipants={getParticipants}
      />

      <Drawer
        title={'Survey Response'}
        placement={'right'}
        closable={false}
        onClose={handleVisibility}
        visible={drawerVisible}
        width={700}
      >
        <SurveyResponse
          questions={questions}
          selectedSurvey={selectedResponse}
          loading={responseLoading}
          canSurveyUpdate={false}
          updateResponseNeedsUpdate={null}
        />
      </Drawer>

      <Title level={2}>Follow Ups</Title>
      <Table
        columns={columns}
        dataSource={followUps}
        rowKey={'survey_response_uuid'}
        size={'small'}
      />
    </div>
  )
}

const mapStateToProps = ({ surveys, questions, responses }) => {
  return {
    followUps: surveys.followUps,
    questions: questions.questions,
    responseLoading: responses.loading,
    selectedResponse: responses.selectedResponse,
  }
}

const mapDispatchToProps = {
  fetchFollowUpDetails,
  getParticipants,
  getResponse,
  getQuestions,
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpComp)
