import { USER } from '@store/action-types'
import * as loginApi from '@api/user.api'
import { removeDate, storeData } from '@utils/storage'

export const onUserLogin = (values) => {
  return {
    type: USER.SET_USER,
    async payload() {
      let res = await loginApi.login(values)
      await storeData(res)
      return res
    },
  }
}

export const setUserDetailsFromLocalStorage = (data) => (dispatch) => {
  return dispatch({
    type: USER.SET_USER,
    payload: data,
  })
}

export const logoutHandler = () => (dispatch) => {
  ;(async () => {
    await removeDate()
  })()

  return dispatch({
    type: USER.UNSET_USER,
    payload: null,
  })
}
