import produce from 'immer'
import { SURVEY } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  selectedSurvey: null,
  followUps: null,
  loading: {
    savingSurvey: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${SURVEY.SET_INIT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      // state.selectedSurvey = action.payload
      state.loading.savingSurvey = false
      break
    }
    case `${SURVEY.SET_INIT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.savingSurvey = true
      break
    }
    case `${SURVEY.SET_INIT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.savingSurvey = false
      break
    }
    case `${SURVEY.SET_FOLLOWUPS}_${PROMISE_STATUS.FULFILLED}`: {
      state.followUps = action.payload
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
