import { SURVEY } from '@store/action-types'
import * as surveyApi from '@api/survey.api'
import { getParticipants } from '@actions/participants.actions'

export const createInitSurvey = (participant_id) => (dispatch) => {
  return dispatch({
    type: SURVEY.SET_INIT_SURVEY,
    async payload() {
      return surveyApi.createInitSurvey(participant_id)
    },
  })

  // return response1.then(() => dispatch(getParticipants()))
}

export const fetchFollowUpDetails = () => (dispatch) => {
  return dispatch({
    type: SURVEY.SET_FOLLOWUPS,
    async payload() {
      return surveyApi.fetchFollowUps()
    },
  })
}

// export const getResponses = () => {
//   return {
//     type: RESPONSES.SET_RESPONSES,
//     async payload() {
//       return await responsesApi.getResponses()
//     },
//   }
// }
//
// export const updateResponseNeedsUpdate = (uuid, needsUpdate) => (dispatch) => {
//   const response1 = dispatch({
//     type: RESPONSES.UPDATE_RESPONSE,
//     async payload() {
//       return await responsesApi.updateResponseNeedsUpdate(uuid, {
//         needsUpdate: needsUpdate,
//       })
//     },
//   })
//
//   return response1.then(() => dispatch(getParticipants()))
// }
