import { DOWNLOAD } from '@store/action-types'
import * as downloadApi from '@api/download.api'
import { saveAs } from 'file-saver'

const FileDownload = require('js-file-download')

export const downloadSurveys = () => (dispatch) => {
  return dispatch({
    type: DOWNLOAD.SURVEY,
    async payload() {
      const resp = await downloadApi.downloadSurvey()
      FileDownload(resp.data, 'elfs_survey.csv')
      return resp
    },
  })
}

export const downloadCalendar = () => (dispatch) => {
  return dispatch({
    type: DOWNLOAD.CALENDAR,
    async payload() {
      const resp = await downloadApi.downloadCalendar()
      FileDownload(resp.data, 'elfs_calendar.csv')
      return resp
    },
  })
}

export const downloadSurveyDetails = () => (dispatch) => {
  return dispatch({
    type: DOWNLOAD.SURVEY_DETAILS,
    async payload() {
      const resp = await downloadApi.downloadSurveyDetails()
      FileDownload(resp.data, 'elfs_survey_details.csv')
      return resp
    },
  })
}
