import EP from '@src/api/endpoints'
import client from '@src/api'

export const getResponse = (id) => {
  return client.get(EP.SURVEYS_ID(id)).then((res) => res.data)
}

export const updateResponseNeedsUpdate = (uuid, data) => {
  return client.put(EP.SURVEYS_ID(uuid), data).then((res) => res.data)
}

export const getResponses = () => {
  return client.get(EP.SURVEYS()).then((res) => res.data)
}
