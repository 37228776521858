import { STATS, SURVEY } from '@store/action-types'
import * as statsApi from '@api/stats.api'

export const getStats = () => (dispatch) => {
  return dispatch({
    type: STATS.SET_STATS,
    async payload() {
      return statsApi.getStats()
    },
  })

  // return response1.then(() => dispatch(getParticipants()))
}
