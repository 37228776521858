import produce from 'immer'
import { PARTICIPANTS } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  participants: [],
  loading: {
    fetchParticipants: false,
    saveAParticipant: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${PARTICIPANTS.SET_PARTICIPANTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participants = action.payload
      state.loading.fetchParticipants = false
      break
    }
    case `${PARTICIPANTS.SET_PARTICIPANTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchParticipants = true
      break
    }
    case `${PARTICIPANTS.SET_PARTICIPANTS}_${PROMISE_STATUS.REJECTED}`: {
      state.participants = []
      state.loading.fetchParticipants = false
      break
    }
    case `${PARTICIPANTS.UPDATE_PARTICIPANTS}_${PROMISE_STATUS.FULFILLED}`: {
      const { uuid } = action.payload
      state.participants = state.participants.map((item) => {
        if (item.uuid === uuid) {
          return action.payload
        } else {
          return item
        }
      })
      state.loading.fetchParticipants = false
      break
    }
    case `${PARTICIPANTS.UPDATE_PARTICIPANTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchParticipants = true
      break
    }
    case `${PARTICIPANTS.UPDATE_PARTICIPANTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchParticipants = false
      break
    }
    case `${PARTICIPANTS.SET_A_PARTICIPANT}_${PROMISE_STATUS.FULFILLED}`: {
      state.participants.unshift(action.payload)
      state.loading.saveAParticipant = false
      break
    }
    case `${PARTICIPANTS.SET_A_PARTICIPANT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.saveAParticipant = true
      break
    }
    case `${PARTICIPANTS.SET_A_PARTICIPANT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.saveAParticipant = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
