import { QUESTIONS } from '@store/action-types'
import * as questionsApi from '@api/questions.api'

export const getQuestions = () => {
  return {
    type: QUESTIONS.SET_QUESTIONS,
    async payload() {
      return await questionsApi.getQuestions()
    },
  }
}
