import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Drawer, Typography, Input, Button, Table } from 'antd'
import useDrawer from '@src/hooks/useDrawer'
import { deleteNote, getNotes, postNote } from '@actions/notes.actions'
import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons'

const { Title } = Typography

const NotesContainer = (props, ref) => {
  const { participantId, getParticipants } = props

  const { handleVisibility, drawerVisible } = useDrawer()
  const [newNote, setNewNote] = useState()
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState()

  useEffect(() => {
    setNotes()
    setLoading(true)
    participantId &&
      getNotes(participantId)
        .then((res) => setNotes(res))
        .finally(() => setLoading(false))
  }, [participantId])

  useImperativeHandle(ref, () => ({
    handleVisibility,
  }))

  const column = [
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (form, item) => item && moment(item).format('DD-MM-YYYY'),
    },
    {
      title: 'Operations',
      width: 100,
      render: (form) => (
        <Button disabled={loading} size={'small'}>
          <DeleteOutlined
            style={{ color: loading ? '#777' : 'red' }}
            onClick={async () => {
              setLoading(true)

              deleteNote(form.uuid)
                .then(() => getNotes(participantId))
                .then((res) => setNotes(res))
                .then(getParticipants)
                .finally(() => setLoading(false))
            }}
          />
        </Button>
      ),
    },
  ]

  if (participantId === null || participantId === undefined) {
    return null
  }

  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={handleVisibility}
      visible={drawerVisible}
      width={700}
      destroyOnClose
    >
      <Title>Notes</Title>

      <div
        style={{ backgroundColor: '#cfd4f8', padding: 10, borderRadius: 10 }}
      >
        <h4>Add A New Note</h4>
        <Input.TextArea
          allowClear
          value={newNote}
          onChange={(event) => setNewNote(event.target.value)}
        />

        <Button
          onClick={async () => {
            setLoading(true)
            postNote(newNote, participantId)
              .then(() => getNotes(participantId))
              .then((res) => setNotes(res))
              .then(() => getParticipants())
              .finally(() => setLoading(false))

            setNewNote('')
          }}
          style={{ marginTop: 10 }}
          disabled={newNote === '' || newNote === undefined || loading}
        >
          Submit
        </Button>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          columns={column}
          dataSource={notes}
          rowKey={'uuid'}
          size={'small'}
          loading={loading}
        />
      </div>
    </Drawer>
  )
}

export default React.forwardRef(NotesContainer)
