export const PROMISE_STATUS = {
  FULFILLED: 'FULFILLED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
}

export const QUESTION_ROWS = {
  QUESTION: 'QUESTION',
  STATS: 'STATS',
  VALUES: 'VALUES',
  LINKING: 'LINKING',
}

export const RESPONSE_TYPES = {
  BOOLEAN: 'BOOLEAN',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  LABEL: 'LABEL',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
}

export const NO_LOGIC = '@@_NO_LOGIC_@@'

export const ROUTES = {
  BUILD: '/build',
  SURVEY: '/survey',
  USER: '/user',
}

export const EXTRACT_METADATA_FROM = {
  SERVER: 'SERVER',
  CURRENT_SURVEY: 'CURRENT_SURVEY',
}

export const COLORS = {
  SELECTED_RESPONSE: '#e5e5e5',
  PRIMARY_COLOR: '#1DA57A',
  SURVEY_COMPLETED: 'rgba(137,236,109,0.65)',
  SURVEY_INIT: 'rgba(227,150,62,0.65)',
  SURVEY_NEEDS_UPDATE: 'rgba(227,62,134,0.65)',
}

export const USERS = {
  RA: 'RA',
  PARTICIPANT: 'PARTICIPANT',
}

export const PARTICIPANT_STATUS = {
  LOST_TO_FOLLOW_UP: 'LOST_TO_FOLLOW_UP',
  SKIPPED: 'SKIPPED',
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
}
