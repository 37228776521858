//MetaData
export const SET_METADATA = 'meta_data/SET_METADATA'

export const USER = {
  SET_USER: 'USER/SET_USER',
  UNSET_USER: 'USER/UNSET_USER',
}

export const PARTICIPANTS = {
  SET_PARTICIPANTS: 'PARTICIPANTS/SET_PARTICIPANTS',
  UPDATE_PARTICIPANTS: 'PARTICIPANTS/UPDATE_PARTICIPANTS',
  SET_A_PARTICIPANT: 'PARTICIPANTS/SET_A_PARTICIPANT',
  GET_PARTICIPANTS_SURVEY: 'PARTICIPANTS/GET_PARTICIPANTS_SURVEY',
}

export const QUESTIONS = {
  SET_QUESTIONS: 'QUESTIONS/SET_QUESTIONS',
}

export const RESPONSES = {
  SET_RESPONSE: 'RESPONSES/SET_RESPONSE',
  SET_RESPONSES: 'RESPONSES/SET_RESPONSES',
  UPDATE_RESPONSE: 'RESPONSES/UPDATE_RESPONSE',
}

export const SURVEY = {
  SET_INIT_SURVEY: 'SURVEY/SET_INIT_SURVEY',
  SET_FOLLOWUPS: 'SURVEY/SET_FOLLOWUPS',
}

export const STATS = {
  SET_STATS: 'SURVEY/SET_STATS',
}

export const DOWNLOAD = {
  SURVEY: 'DOWNLOAD/SURVEY',
  CALENDAR: 'DOWNLOAD/CALENDAR',
  SURVEY_DETAILS: 'DOWNLOAD/SURVEY_DETAILS',
}
