import produce from 'immer'
import { QUESTIONS } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  questions: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${QUESTIONS.SET_QUESTIONS}_${PROMISE_STATUS.FULFILLED}`: {
      state.questions = action.payload
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
