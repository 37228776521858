import React from 'react'
import moment from 'moment'
import { Empty, Tag, Timeline, Typography } from 'antd'
import {
  ClockCircleOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons'

const NotificationTimeline = ({ data }) => {
  if (data === null || data === undefined || data?.length === 0) {
    return <Empty description={'No notifications'} />
  } else {
    return (
      <div>
        <Typography.Title level={5}>Notifications:</Typography.Title>
        <Timeline>
          {data.map(({ created_at, type }) => (
            <Timeline.Item
              key={created_at}
              dot={
                type === 'NEEDS_UPDATE' ? (
                  <WarningOutlined style={{ fontSize: '16px' }} />
                ) : type === 'INIT' ? (
                  <PlusOutlined style={{ fontSize: '16px' }} />
                ) : (
                  <ClockCircleOutlined style={{ fontSize: '16px' }} />
                )
              }
            >
              <span>
                {' '}
                {new Date(created_at).toUTCString().replace('GMT', '')}
              </span>
              <Tag style={{ marginLeft: 25 }} color={'orange'}>
                {type}
              </Tag>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    )
  }
}

export default function NotificationsTable({ data }) {
  return (
    <div style={{ padding: 10 }}>
      <NotificationTimeline data={data} />
    </div>
  )
}
