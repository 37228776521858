import React from 'react'
import { Button, Checkbox, Form, Input, Select, DatePicker } from 'antd'
import moment from 'moment'

const { Option } = Select

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
}

const prefixSelector = (
  <Form.Item
    name="countryCode"
    noStyle
    rules={[{ required: true, message: 'Please input mobile prefix!' }]}
  >
    <Select style={{ width: 200 }}>
      <Option value="61">+61 - Australia</Option>
      <Option value="84">+84 - Vietnam</Option>
      <Option value="972">+972 - Israel</Option>
    </Select>
  </Form.Item>
)

const AddAParticipantForm = ({ onSubmit, loading, data }) => {
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  if (data) {
    const { dob } = data
    const modData = moment(dob, 'YYYY-MM-DD')
    data = {
      ...data,
      dob: modData,
    }
  }

  return (
    <Form
      {...layout}
      name="addAParticipant"
      initialValues={data}
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input participant email!' }]}
      >
        <Input type={'email'} />
      </Form.Item>

      <Form.Item
        label="DOB"
        name="dob"
        rules={[{ required: true, message: 'Please input participant dob!' }]}
      >
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>

      <Form.Item
        label="Platform"
        name="platform"
        rules={[
          {
            required: true,
            message: 'Please input participants preferred platform!',
          },
        ]}
      >
        <Select style={{ width: '100%' }}>
          <Option value="web">Web</Option>
          <Option value="mobile">Mobile</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Participant Code"
        name="participantId"
        rules={[{ required: true, message: 'Please input participant code!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Language"
        name="language"
        rules={[
          { required: true, message: 'Please input participants language!' },
        ]}
      >
        <Select style={{ width: '100%' }}>
          <Option value="English">English</Option>
          <Option value="Vietnamese">Vietnamese</Option>
          <Option value="Hebrew">Hebrew</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="mobileNo"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: 'Please input participants phone number!',
          },
        ]}
      >
        <Input
          addonBefore={prefixSelector}
          style={{ width: '100%' }}
          type={'number'}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          disabled={loading}
        >
          {data ? 'Update' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddAParticipantForm
