import { RESPONSES } from '@store/action-types'
import * as responsesApi from '@api/responses.api'
import { getParticipants } from '@actions/participants.actions'

export const getResponse = (id) => {
  return {
    type: RESPONSES.SET_RESPONSE,
    async payload() {
      return responsesApi.getResponse(id)
    },
  }
}

export const getResponses = () => {
  return {
    type: RESPONSES.SET_RESPONSES,
    async payload() {
      return await responsesApi.getResponses()
    },
  }
}

export const updateResponseNeedsUpdate = (uuid, status) => (dispatch) => {
  return dispatch({
    type: RESPONSES.UPDATE_RESPONSE,
    async payload() {
      return await responsesApi.updateResponseNeedsUpdate(uuid, {
        status: status,
      })
    },
  })

  // return response1.then(() => dispatch(getParticipants()))
}
