import React, { useImperativeHandle } from 'react'
import { Col, Drawer, Row, Switch, Typography } from 'antd'
import useDrawer from '@src/hooks/useDrawer'
import { PARTICIPANT_STATUS } from '@constants/strings'

const { Title } = Typography

const SettingsContainer = (props, ref) => {
  const { participant, patchAParticipant } = props

  const { handleVisibility, drawerVisible } = useDrawer()

  useImperativeHandle(ref, () => ({
    handleVisibility,
  }))

  if (participant === null || participant === undefined) {
    return null
  }

  return (
    <Drawer
      placement={'right'}
      closable={false}
      onClose={handleVisibility}
      visible={drawerVisible}
      width={700}
      destroyOnClose
    >
      <Title>Settings</Title>

      <Row style={{ backgroundColor: '#e2e6f6', padding: 8 }}>
        <Col
          style={{
            paddingRight: 20,
            alignSelf: 'center',
          }}
        >
          <h3>Mark participant as lost to follow?</h3>
        </Col>
        <Col>
          <Switch
            defaultChecked={
              participant.status === PARTICIPANT_STATUS.LOST_TO_FOLLOW_UP
            }
            onChange={(val) =>
              patchAParticipant(participant.uuid, {
                status: val
                  ? PARTICIPANT_STATUS.LOST_TO_FOLLOW_UP
                  : PARTICIPANT_STATUS.ACTIVE,
              })
            }
          />
        </Col>
      </Row>
    </Drawer>
  )
}

export default React.forwardRef(SettingsContainer)
