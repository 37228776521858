import produce from 'immer'
import { DOWNLOAD } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  loading: {
    survey: false,
    calendar: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${DOWNLOAD.SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.survey = false
      break
    }
    case `${DOWNLOAD.SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.survey = true
      break
    }
    case `${DOWNLOAD.SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.survey = false
      break
    }
    case `${DOWNLOAD.CALENDAR}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.calendar = false
      break
    }
    case `${DOWNLOAD.CALENDAR}_${PROMISE_STATUS.PENDING}`: {
      state.loading.calendar = true
      break
    }
    case `${DOWNLOAD.CALENDAR}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.calendar = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
