import { combineReducers } from 'redux'
import user from './user.reducers'
import participants from './participants.reducers'
import questions from './questions.reducers'
import responses from './responses.reducers'
import stats from './stats.reducers'
import downloads from './download.reducers'
import surveys from './survey.reducers'

// Add your new reducer here
const reducers = {
  users: user,
  participants,
  questions,
  responses,
  stats: stats,
  downloads,
  surveys,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
