require('dotenv').config()

const BASE = `http://localhost:8888`
const API_BASE = process.env.REACT_APP_API_HOST || 'http://127.0.0.1:8000/api'
// const API_BASE = `http://45.113.235.112/api`

const ENDPOINTS = {
  LOGIN: () => `${API_BASE}/auth/login`,
  PARTICIPANTS: () => `${API_BASE}/admin/participants`,
  PARTICIPANTS_ID: (participantId) =>
    `${API_BASE}/admin/participants/${participantId}`,
  PARTICIPANTS_SURVEY: (uuid) =>
    `${API_BASE}/admin/participants/${uuid}/survey`,
  SURVEYS: () => `${API_BASE}/admin/surveys`,
  SURVEY_INIT_CREATE: (participant_id) =>
    `${API_BASE}/admin/surveys/create/${participant_id}`,
  SURVEYS_ID: (id) => `${API_BASE}/admin/surveys/${id}`,
  QUESTIONS: () => `${API_BASE}/questions`,
  STATS: () => `${API_BASE}/admin/stats/dashboard`,
  DOWNLOADS: (_type) => `${API_BASE}/admin/download/${_type}`,
  NOTES_ID: (notes_id) => `${API_BASE}/notes/${notes_id}`,
  NOTES_PARTICIPANT_ID: (participant_id) =>
    `${API_BASE}/notes/participants/${participant_id}`,
  SMS_REMAINDER: (participant_id) =>
    `${API_BASE}/sms/participants/${participant_id}/remainder`,
  FOLLOW_UPS: () => `${API_BASE}/admin/participants/follow-up`,
}

export default ENDPOINTS
