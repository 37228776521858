import EP from '@src/api/endpoints'
import client from '@src/api'

export const downloadSurvey = () => {
  return client.get(EP.DOWNLOADS('surveys')).then((res) => res)
}

export const downloadCalendar = () => {
  return client.get(EP.DOWNLOADS('calendar')).then((res) => res)
}

export const downloadSurveyDetails = () => {
  return client.get(EP.DOWNLOADS('survey-details')).then((res) => res)
}
