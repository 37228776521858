import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Typography, Button, Drawer, Table, notification, Input } from 'antd'
import {
  getParticipants,
  patchAParticipant,
  postAParticipant,
  updateAParticipant,
} from '@actions/participants.actions'
import { getQuestions } from '@actions/questions.actions'
import {
  getResponse,
  updateResponseNeedsUpdate,
} from '@actions/responses.actions'
import useDrawer from '@src/hooks/useDrawer'
import AddAParticipantForm from '@components/forms/AddAParticipant'
import {
  EditOutlined,
  CommentOutlined,
  SettingOutlined,
  ArrowsAltOutlined,
  ShrinkOutlined,
  DownloadOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { createInitSurvey } from '@actions/survey.actions'
import SurveyTable from '@containers/participants/surveys'
import NotesContainer from '@containers/participants/notes'
import SettingsContainer from '@containers/participants/settings'
import { downloadSurveyDetails } from '@actions/download.actions'

const { Title } = Typography

const DRAWER_SCREEN = {
  ADD_PARTICIPANT: 'ADD_PARTICIPANT',
  EDIT_PARTICIPANT: 'EDIT_PARTICIPANT',
}

const ParticipantsContainer = ({
  getQuestions,
  getParticipants,
  participants,
  loading,
  postAParticipant,
  updateAParticipant,
  patchAParticipant,
  downloadSurveyDetails,
}) => {
  const { handleVisibility, drawerVisible } = useDrawer()
  const [currentDrawerScreen, setCurrentDrawerScreen] = useState(null)
  const [participantToUpdateData, setParticipantToUpdateData] = useState(null)
  const [selectedParticipantId, setSelectedParticipantId] = useState()
  const [selectedParticipant, setSelectedParticipant] = useState()
  const [expandKeys, setExpandKeys] = useState([])
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState()

  const notesRef = useRef()
  const settingsRef = useRef()

  useEffect(() => {
    getParticipants()
    getQuestions()
    setData(participants)
  }, [getParticipants, getQuestions])

  useEffect(() => {
    setData(participants)
  }, [participants])

  const filterVals = useCallback(
    (e) => {
      const currValue = e.target.value

      setSearchText(currValue)

      if (currValue === '' || currValue === null || currValue === undefined) {
        setData(participants)
      } else {
        try {
          const filteredVals = participants.filter((entry) => {
            const { participantId, email } = entry
            return (
              participantId.toLowerCase().includes(currValue.toLowerCase()) ||
              email.toLowerCase().includes(currValue.toLowerCase())
            )
          })
          setData(filteredVals)
        } catch (e) {
          setData([])
        }
      }
    },
    [loading]
  )

  // const content = (
  //   <div>
  //     <Typography.Title level={5}>Send SMS Notification</Typography.Title>
  //   </div>
  // )

  const columns = [
    {
      title: 'Participant Id',
      dataIndex: 'participantId',
      key: 'participantId',
    },
    {
      title: 'Phone No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
      render: (text, form) => (
        <span>
          {form.countryCode} - {form.mobileNo}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      filters: [
        { text: 'English', value: 'English' },
        { text: 'Vietnamese', value: 'Vietnamese' },
        { text: 'Hebrew', value: 'Hebrew' },
      ],
      onFilter: (value, record) => record.language === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          text: 'COMPLETED',
          value: 'COMPLETED',
        },
        {
          text: 'SKIPPED',
          value: 'SKIPPED',
        },
        {
          text: 'LOST_TO_FOLLOW_UP',
          value: 'LOST_TO_FOLLOW_UP',
        },
        {
          text: 'ALERT_TO_RA',
          value: 'ALERT_TO_RA',
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: '# of surveys',
      dataIndex: 'noOfSurveys',
      key: 'noOfSurveys',
    },
    // {
    //   title: 'Cycle Type',
    //   dataIndex: 'periodCycleType',
    //   key: 'periodCycleType',
    // },
    {
      title: 'Operations',
      render: (form) => (
        <div>
          <Button
            size={'small'}
            type={'primary'}
            onClick={() => onEditParticipantBtnClick(form)}
          >
            <EditOutlined />
          </Button>

          <Button
            size={'small'}
            style={{ marginLeft: 10 }}
            type={'primary'}
            onClick={() => {
              setSelectedParticipantId(form.uuid)
              setSelectedParticipant(form)
              notesRef.current.handleVisibility()
            }}
          >
            <div>
              <span style={{ paddingRight: 4 }}>{form.noOfNotes}</span>
              <CommentOutlined />
            </div>
          </Button>

          <Button
            size={'small'}
            style={{ marginLeft: 10 }}
            type={'primary'}
            onClick={() => {
              setSelectedParticipantId(form.uuid)
              setSelectedParticipant(form)
              settingsRef.current.handleVisibility()
            }}
          >
            <SettingOutlined />
          </Button>
        </div>
      ),
    },
  ]

  const onAddParticipantBtnClick = () => {
    setParticipantToUpdateData(null)
    handleVisibility()
    setCurrentDrawerScreen(DRAWER_SCREEN.ADD_PARTICIPANT)
  }

  const onEditParticipantBtnClick = (form) => {
    setParticipantToUpdateData(form)
    handleVisibility()
    setCurrentDrawerScreen(DRAWER_SCREEN.EDIT_PARTICIPANT)
  }

  const handleAddAParticipant = async (data) => {
    const { dob } = data
    const modDOB = dob.format('YYYY-MM-DD')
    const modData = {
      ...data,
      dob: modDOB,
    }
    if (participantToUpdateData) {
      await updateAParticipant(participantToUpdateData.uuid, modData)
    } else {
      await postAParticipant(modData)
    }

    handleVisibility()
    notification.success({
      message: 'SUCCESS',
      description: 'Participant saved successfully',
    })
  }

  const handleTableExpansion = () => {
    if (expandKeys.length >= 1) {
      setExpandKeys([])
    } else {
      let expandKeys = participants.map(({ uuid }) => uuid)
      setExpandKeys(expandKeys)
    }
  }

  return (
    <div
      style={{
        width: '100%',
        // padding: 50,
      }}
    >
      <NotesContainer
        ref={notesRef}
        participantId={selectedParticipantId}
        getParticipants={getParticipants}
      />
      <SettingsContainer
        ref={settingsRef}
        participantId={selectedParticipantId}
        participant={selectedParticipant}
        patchAParticipant={patchAParticipant}
      />

      <Drawer
        title={
          DRAWER_SCREEN.ADD_PARTICIPANT === currentDrawerScreen
            ? 'Add A Participant'
            : 'Update Participant'
        }
        placement={'right'}
        closable={false}
        onClose={handleVisibility}
        visible={drawerVisible}
        width={700}
        destroyOnClose
      >
        <AddAParticipantForm
          onSubmit={handleAddAParticipant}
          loading={loading.saveAParticipant}
          data={participantToUpdateData}
        />
      </Drawer>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
          <Title level={2}>Participants Details</Title>
          <div>
            <Input
              style={{ width: 250, marginTop: 7, marginLeft: 10 }}
              placeholder="Filter: Email or participant ID"
              onChange={filterVals}
            />
          </div>
        </div>

        <div>
          <Button
            type={'primary'}
            onClick={() => downloadSurveyDetails()}
            style={{ marginLeft: 10 }}
          >
            <DownloadOutlined />
            Download Surveys
          </Button>
        </div>

        <div>
          <Button
            onClick={onAddParticipantBtnClick}
            type={'primary'}
            style={{ marginLeft: 10 }}
          >
            <UserAddOutlined />
            Add Participant
          </Button>
        </div>
      </div>

      <div style={{ marginBottom: 20 }}>
        <Button
          onClick={handleTableExpansion}
          type={'default'}
          style={{ marginLeft: 10 }}
        >
          {expandKeys?.length >= 1 ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
          {expandKeys?.length >= 1 ? 'Minimize' : 'Expand'} Table
        </Button>
      </div>

      <Table
        dataSource={data}
        loading={loading.fetchParticipants}
        columns={columns}
        key="loading-done"
        style={{ minWidth: 950 }}
        pagination={{ pageSize: 100 }}
        rowClassName={({ status, noOfSurveys }, index) =>
          noOfSurveys === 0 || !noOfSurveys
            ? 'table-row-needs-update'
            : status === 'COMPLETED'
            ? 'table-row-completed'
            : status === 'SKIPPED'
            ? 'table-row-skipped'
            : status === 'ALERT_TO_RA'
            ? 'table-row-alert-to-ra'
            : ''
        }
        size="small"
        rowKey="uuid"
        expandable={{
          // defaultExpandAllRows: expandAll,
          expandedRowRender: (row) => {
            const { uuid } = row
            return <SurveyTable uuid={uuid} />
          },
          onExpand: (expanded, { uuid }) => {
            if (expanded) {
              setExpandKeys((prevState) => [...prevState, uuid])
            } else {
              let filtered = expandKeys.filter((item) => item !== uuid)
              setExpandKeys(filtered)
            }
          },
          expandedRowKeys: expandKeys,
        }}
      />
    </div>
  )
}

const mapStateToProps = ({ user, participants, questions, responses }) => {
  return {
    participants: participants.participants,
    questions: questions.questions,
    selectedResponse: responses.selectedResponse,
    loading: participants.loading,
    responseLoading: responses.loading,
  }
}

const mapDispatchToProps = {
  getParticipants,
  getQuestions,
  getResponse,
  postAParticipant,
  updateResponseNeedsUpdate,
  createInitSurvey,
  updateAParticipant,
  patchAParticipant,
  downloadSurveyDetails,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantsContainer)
