import React from 'react'
import { Button } from 'antd'
import { LoadingIndicator } from '@components/loading'

const SurveyResponse = ({
  questions,
  selectedSurvey,
  updateResponseNeedsUpdate,
  loading,
  canSurveyUpdate,
  // surveyUuid,
  // surveyStatus,
}) => {
  const { updatingResponse, fetchingResponses } = loading

  const renderSurvey = () => {
    const { surveyResponse } = selectedSurvey
    let index = 1
    return Object.keys(surveyResponse)
      .map((key) => {
        if (surveyResponse[key] !== null) {
          let question = questions.find((question) => question.key === key)
          let value = surveyResponse[key]

          const renderValues = () => {
            if (typeof value === 'boolean') {
              if (value) {
                return 'Yes'
              } else {
                return 'No'
              }
            } else if (Array.isArray(value)) {
              return value.map((item, index) => {
                return (
                  <p key={item}>
                    {index + 1}. {item}
                  </p>
                )
              })
            } else {
              return value
            }
          }

          if (question) {
            return (
              <div key={key} className="survey-response-div">
                <p>
                  <b>
                    {index++}. {question?.questionText}
                  </b>
                </p>
                <div style={{ marginLeft: 10 }}>
                  <i>{renderValues()}</i>
                </div>
              </div>
            )
          }
        }
      })
      .filter((value) => value !== undefined)
  }

  if (fetchingResponses) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <h2>Fetching...</h2>
        <LoadingIndicator />
      </div>
    )
  } else if (selectedSurvey?.surveyResponse) {
    const { status, uuid } = selectedSurvey

    return (
      <div>
        {canSurveyUpdate && (
          <>
            {status === 'NEEDS_UPDATE' ? (
              <Button
                type={'primary'}
                danger
                disabled={updatingResponse}
                onClick={() => updateResponseNeedsUpdate(uuid, 'COMPLETED')}
              >
                Lock Survey
              </Button>
            ) : (
              <Button
                type={'primary'}
                disabled={updatingResponse}
                onClick={() => updateResponseNeedsUpdate(uuid, 'NEEDS_UPDATE')}
              >
                Unlock Survey
              </Button>
            )}
          </>
        )}

        <div style={{ marginTop: 20 }}>{renderSurvey()}</div>
      </div>
    )
  } else {
    return null
  }

  // return (
  //   response && (
  //     <div>
  //       {surveyStatus === 'NEEDS_UPDATE' ? (
  //         <Button
  //           type={'primary'}
  //           danger
  //           disabled={updatingResponse}
  //           onClick={() => updateResponseNeedsUpdate(surveyUuid, 'COMPLETED')}
  //         >
  //           Lock Survey
  //         </Button>
  //       ) : (
  //         <Button
  //           type={'primary'}
  //           disabled={updatingResponse}
  //           onClick={() =>
  //             updateResponseNeedsUpdate(surveyUuid, 'NEEDS_UPDATE')
  //           }
  //         >
  //           Unlock Survey
  //         </Button>
  //       )}
  //
  //       <div style={{ marginTop: 20 }}>{renderSurvey()}</div>
  //     </div>
  //   )
  // )
}

export default SurveyResponse
