import React from 'react'
import { Switch, Route } from 'react-router-dom'
import LoginContainer from '@containers/login'
import DashboardContainer from '@containers/dashboard'
import ParticipantsContainer from '@containers/participants'
import SurveysContainer from '@containers/surveys'
import { connect } from 'react-redux'
import LogoutContainer from '@containers/logout'
import ROUTES from '@constants/routes'
import FollowUpComp from '@containers/followup'

function AppRoutes({ userBio }) {
  if (userBio) {
    return (
      <Switch>
        <Route path="/logout" component={LogoutContainer} />
        <Route path="/participants" component={ParticipantsContainer} />
        <Route path={ROUTES.FOLLOW_UPS} component={FollowUpComp} />
        {/*<Route path="/surveys" component={SurveysContainer} />*/}
        <Route path="/" component={DashboardContainer} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/" component={LoginContainer} />
      </Switch>
    )
  }
}

const mapStateToProps = ({ users }) => {
  return {
    userBio: users.bio,
  }
}

export default connect(mapStateToProps)(AppRoutes)
