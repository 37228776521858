import * as notesApi from '@api/notes.api'

export const getNotes = async (participant_id) => {
  return await notesApi.getNotes(participant_id)
}

export const postNote = async (note, participant_id) => {
  return await notesApi.postANote(participant_id, {
    note,
  })
}

export const deleteNote = async (note_id) => {
  return await notesApi.deleteNote(note_id)
}
