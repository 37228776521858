import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { logoutHandler } from '@actions/user.actions'
import { LoadingIndicator } from '@components/loading'

const LogoutContainer = ({ logoutHandler }) => {
  useEffect(() => {
    logoutHandler()
  }, [])
  return <LoadingIndicator />
}

const mapDispatchToProps = {
  logoutHandler,
}

export default connect(null, mapDispatchToProps)(LogoutContainer)
