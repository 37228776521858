import React from 'react'
import './styles/App.less'
import { Provider } from 'react-redux'
import configureStore from '@store'
// import Routers from './router/index'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLayout from '@src/layout'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppLayout />
      </Router>
    </Provider>
  )
}

export default App
