import React, { useEffect, useState } from 'react'
import { Button, Drawer, notification, Popconfirm, Table } from 'antd'
import moment from 'moment'
import { getParticipantsSurvey } from '@actions/participants.actions'
import { connect } from 'react-redux'
import { createInitSurvey } from '@actions/survey.actions'
import { LoadingIndicator } from '@components/loading'
import NotificationsTable from '@containers/participants/notifications'
import SurveyResponse from '@components/SurveyResponse'
import {
  getResponse,
  updateResponseNeedsUpdate,
} from '@actions/responses.actions'
import useDrawer from '@src/hooks/useDrawer'
import { sendRemainder } from '@actions/sms.actions'

const SurveyTable = ({
  uuid,
  getParticipantsSurvey,
  createInitSurvey,
  questions,
  getResponse,
  selectedResponse,
  updateResponseNeedsUpdate,
  responseLoading,
  participantsLoading,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedSurveyDetails, setSelectedSurveyDetails] = useState({})
  const { handleVisibility, drawerVisible } = useDrawer()
  const [canCurrentSurveyUpdate, setCanCurrentSurveyUpdate] = useState()

  const columns = [
    {
      title: 'Survey#',
      dataIndex: 'survey_number',
      key: 'survey_number',
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAn',
    //   render: (text) => moment(text).format('DD-MM-YYYY:LT'),
    // },
    {
      title: 'LMP',
      dataIndex: 'lmp',
      key: 'lmp',
    },
    {
      title: 'LMP Diff',
      dataIndex: 'lmp_diff',
      key: 'lmp_diff',
    },
    {
      title: 'LMP - Notification Diff',
      dataIndex: 'notification_lmp_diff',
      key: 'notification_lmp_diff',
    },
    {
      title: 'Last Notification',
      dataIndex: 'latest_notification',
      key: 'latest_notification',
    },
    {
      title: 'Submitted On',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
      render: (text) => (text ? moment(text).format('DD-MM-YYYY') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '#Notifications',
      render: (form) => {
        const { survey_notifications } = form
        return survey_notifications?.length
      },
    },
    {
      title: 'Operations',
      render: (form) => {
        const { status } = form
        if (status === 'INIT') {
          return (
            <Popconfirm
              title="Are you sure to send a remainder?"
              onConfirm={() => {
                setLoading(true)
                sendRemainder(uuid)
                  .then(async () => {
                    notification.open({
                      message: 'SMS Sent',
                      description: 'Remainder notification successfully sent',
                    })
                    await getSurvey()
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger size={'small'} disabled={loading}>
                Send Remainder
              </Button>
            </Popconfirm>
          )
        }
        return null
      },
    },
  ]

  useEffect(() => {
    //  fetch survey info
    getSurvey()
  }, [uuid])

  const getSurvey = () => {
    setLoading(true)
    getParticipantsSurvey(uuid)
      .then((res) => {
        setData(res.value)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const sendInitialSurvey = () => {
    createInitSurvey(uuid)
      .then(() => getParticipantsSurvey(uuid))
      .then((res) => {
        setData(res.value)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const handleUpdateResponseNeedsUpdate = (surveyUuid, status) => {
    updateResponseNeedsUpdate(surveyUuid, status)
      .then(() => {
        handleVisibility()
        return getParticipantsSurvey(uuid)
      })
      .then((res) => {
        setData(res.value)
      })
      .catch(() => setLoading(false))
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div style={{ backgroundColor: 'rgba(195,239,206,0.27)', padding: 15 }}>
      <Drawer
        title={'Survey Response'}
        placement={'right'}
        closable={false}
        onClose={handleVisibility}
        visible={drawerVisible}
        width={700}
      >
        <SurveyResponse
          questions={questions}
          selectedSurvey={selectedResponse}
          loading={responseLoading}
          canSurveyUpdate={canCurrentSurveyUpdate}
          updateResponseNeedsUpdate={handleUpdateResponseNeedsUpdate}
        />
      </Drawer>

      {data.length === 0 && (
        <div>
          <h3>Participant has no survey</h3>
          <Button
            onClick={() => sendInitialSurvey()}
            type={'primary'}
            disabled={participantsLoading.saveAParticipant}
          >
            Send Initial Survey
          </Button>
        </div>
      )}

      {data.length >= 1 && (
        <div>
          {/*<p>Next survey will be sent on {new Date().toDateString()}</p>*/}
          <Table
            rowClassName={({ status }, index) =>
              status === 'INIT'
                ? 'table-row-init'
                : status === 'COMPLETED'
                ? 'table-row-completed'
                : 'table-row-needs-update'
            }
            dataSource={data}
            columns={columns}
            rowKey={'uuid'}
            loading={loading}
            onRow={(record, rowIndex) => {
              const { status } = record
              return {
                onClick: (event) => {
                  if (status !== 'INIT') {
                    const { uuid } = record
                    handleVisibility()
                    if (
                      (rowIndex === 0 && status === 'COMPLETED') ||
                      status === 'NEEDS_UPDATE'
                    ) {
                      setCanCurrentSurveyUpdate(true)
                    } else {
                      setCanCurrentSurveyUpdate(false)
                    }
                    getResponse(uuid)
                  }
                },
              }
            }}
            expandable={{
              expandedRowRender: (row) => {
                const { survey_notifications } = row
                return <NotificationsTable data={survey_notifications} />
              },
              rowExpandable: (record) =>
                record?.survey_notifications?.length !== 0,
            }}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ questions, responses, participants }) => ({
  questions: questions.questions,
  responseLoading: responses.loading,
  selectedResponse: responses.selectedResponse,
  participantsLoading: participants.loading,
})

const mapDispatchToProps = {
  getParticipantsSurvey,
  createInitSurvey,
  updateResponseNeedsUpdate,
  getResponse,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTable)
