import produce from 'immer'
import { USER } from '@store/action-types'
import { ActionType } from 'redux-promise-middleware'

const initialState = {
  bio: null,
  loading: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case USER.SET_USER: {
      state.bio = action.payload
      break
    }
    case `${USER.SET_USER}_${ActionType.Fulfilled}`: {
      state.bio = action.payload
      state.loading = false
      break
    }
    case `${USER.SET_USER}_${ActionType.Rejected}`: {
      state.bio = null
      state.loading = false
      break
    }
    case `${USER.SET_USER}_${ActionType.Pending}`: {
      state.loading = true
      break
    }
    case USER.UNSET_USER: {
      state.bio = null
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
