import React, { useEffect, useState } from 'react'
import { Layout, Menu, Spin } from 'antd'
import AppRoutes from '@src/router'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MenuBar from '@components/appSidebar'
import { LogoutOutlined } from '@ant-design/icons'
import { getAllData, getData } from '@utils/storage'
import {
  logoutHandler,
  setUserDetailsFromLocalStorage,
} from '@actions/user.actions'
import { useDispatch } from 'react-redux'
import { LoadingIndicator } from '@components/loading'
const { Header } = Layout

const AppHeader = ({ userBio, logoutHandler }) => {
  return (
    <Header className="header">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <span
          style={{ marginLeft: 20, cursor: 'pointer' }}
          onClick={() => logoutHandler()}
        >
          <LogoutOutlined style={{ color: 'white' }} />
        </span>
        <span style={{ color: 'white' }}>Welcome, {userBio.name}</span>
      </div>
    </Header>
  )
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function AppLayout({ userBio, logoutHandler, navigation }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    timeout(500)
      .then(() => getAllData())
      .then((res) => dispatch(setUserDetailsFromLocalStorage(res)))
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <Layout
        style={{
          padding: '30px 20px',
          display: 'flex',
          alignItems: 'center',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator size={150} />
      </Layout>
    )
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {userBio && <AppHeader userBio={userBio} logoutHandler={logoutHandler} />}
      <Layout>
        {userBio && <MenuBar />}
        <Layout
          style={{
            padding: '30px 20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AppRoutes />
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({ users }) => {
  return {
    userBio: users.bio,
  }
}

const mapDispatchToProps = {
  logoutHandler,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout)
)
