import React, { useEffect } from 'react'
import { Button, Card, Divider, Row, Statistic, Typography } from 'antd'
import {
  CalendarOutlined,
  CheckOutlined,
  ExclamationOutlined,
  OrderedListOutlined,
  UserDeleteOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { getStats } from '@actions/stats.actions'
import { connect } from 'react-redux'
import { downloadCalendar, downloadSurveys } from '@actions/download.actions'

const StatsDisplay = ({ data, title }) => {
  const render = () =>
    data.map((item) => {
      const { icon, title, value, color } = item
      return (
        <Card key={title}>
          <Statistic
            title={title}
            value={value}
            valueStyle={{ color: color }}
            prefix={icon}
          />
        </Card>
      )
    })

  return (
    <div>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Row>{render()}</Row>
    </div>
  )
}

const DashboardContainer = ({
  getStats,
  stats,
  downloadSurveys,
  downloadCalendar,
  downloadLoadings,
}) => {
  useEffect(() => {
    getStats()
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Typography.Title level={4}>DOWNLOAD:</Typography.Title>
        <Row>
          <Button
            type={'primary'}
            style={{ marginRight: 10 }}
            icon={<OrderedListOutlined />}
            disabled={downloadLoadings.survey}
            onClick={() => downloadSurveys()}
          >
            Survey
          </Button>
          <Button
            type={'primary'}
            icon={<CalendarOutlined />}
            disabled={downloadLoadings.calendar}
            onClick={() => downloadCalendar()}
          >
            Calendar
          </Button>
        </Row>
        <Divider />

        {stats && (
          <div>
            <StatsDisplay
              title={'PARTICIPANTS:'}
              data={[
                {
                  title: 'Total',
                  value: stats.participants.total,
                  icon: <UserOutlined />,
                  color: '#000',
                },
                {
                  title: 'Completed (Live birth)',
                  value: stats.participants.completed,
                  icon: <CheckOutlined />,
                  color: '#3f8600',
                },
                {
                  title: 'Active',
                  value: stats.participants.active,
                  icon: <UserOutlined />,
                  color: '#3f8600',
                },
                {
                  title: 'Withdrawn',
                  value: stats.participants.withdrawn,
                  icon: <UserDeleteOutlined />,
                  color: '#cf1322',
                },
              ]}
            />

            <Divider style={{ backgroundColor: 'orange' }} />

            <StatsDisplay
              title={'SURVEYS:'}
              data={[
                {
                  title: 'Total',
                  value: stats.surveys.total,
                  icon: <OrderedListOutlined />,
                  color: '#000',
                },
                {
                  title: 'Init',
                  value: stats.surveys.init,
                  icon: <ExclamationOutlined />,
                  color: '#ff4900',
                },
                {
                  title: 'Completed',
                  value: stats.surveys.completed,
                  icon: <CheckOutlined />,
                  color: '#3f8600',
                },
                {
                  title: 'Needs Update',
                  value: stats.surveys.needs_update,
                  icon: <WarningOutlined />,
                  color: '#cf1322',
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ stats, downloads }) => {
  return {
    stats: stats.stats,
    downloadLoadings: downloads.loading,
  }
}

const mapDispatchToProps = {
  getStats,
  downloadSurveys,
  downloadCalendar,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
