import { Layout, Menu } from 'antd'
import React, { useState } from 'react'
import {
  AppstoreOutlined,
  IssuesCloseOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Link, withRouter } from 'react-router-dom'
import ROUTES from '@constants/routes'
// import style from './style.module.less'

const { Sider } = Layout
const { SubMenu } = Menu

function AppSidebar(props) {
  const [menuCollapsed, setMenuCollapsed] = useState(true)
  const pathname = props.history.location.pathname

  const handleClick = (e) => {
    console.log('click ', e)
  }

  const renderAdminMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        style={{ height: '100%', borderRight: 0 }}
        defaultSelectedKeys={[pathname === '/' ? ROUTES.DASHBOARD : pathname]}
        mode="inline"
      >
        <Menu.Item key={ROUTES.DASHBOARD} icon={<AppstoreOutlined />}>
          <Link to={ROUTES.DASHBOARD}>Dashboard </Link>
        </Menu.Item>

        <Menu.Item key={ROUTES.PARTICIPANTS} icon={<UserOutlined />}>
          <Link to={ROUTES.PARTICIPANTS}>Participants</Link>
        </Menu.Item>

        <Menu.Item key={ROUTES.FOLLOW_UPS} icon={<IssuesCloseOutlined />}>
          <Link to={ROUTES.FOLLOW_UPS}>Follow Ups</Link>
        </Menu.Item>

        {/*<SubMenu key="sub4" icon={<SettingOutlined />} title="Settings">*/}
        {/*  <Menu.Item key="9">Option 9</Menu.Item>*/}
        {/*  <Menu.Item key="10">Option 10</Menu.Item>*/}
        {/*  <Menu.Item key="11">Option 11</Menu.Item>*/}
        {/*  <Menu.Item key="12">Option 12</Menu.Item>*/}
        {/*</SubMenu>*/}
      </Menu>
    )
  }

  return (
    <Sider width={200} collapsible>
      {renderAdminMenu()}
    </Sider>
  )
}

export default withRouter(AppSidebar)
