import EP from '@src/api/endpoints'
import client from '@src/api'

export const createInitSurvey = (participant_id) => {
  return client
    .post(EP.SURVEY_INIT_CREATE(participant_id))
    .then((res) => res.data)
}

export const fetchFollowUps = () => {
  return client.get(EP.FOLLOW_UPS()).then((res) => res.data)
}
