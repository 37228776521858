import produce from 'immer'
import { RESPONSES } from '@store/action-types'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  selectedResponse: null,
  responses: [],
  loading: {
    fetchingResponses: false,
    updatingResponse: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${RESPONSES.SET_RESPONSE}_${PROMISE_STATUS.FULFILLED}`: {
      state.selectedResponse = action.payload
      state.loading.fetchingResponses = false
      break
    }
    case `${RESPONSES.SET_RESPONSE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchingResponses = true
      break
    }
    case `${RESPONSES.SET_RESPONSE}_${PROMISE_STATUS.REJECTED}`: {
      state.selectedResponse = null
      state.loading.fetchingResponses = false
      break
    }
    case `${RESPONSES.UPDATE_RESPONSE}_${PROMISE_STATUS.FULFILLED}`: {
      state.selectedResponse = action.payload
      state.loading.updatingResponse = false
      break
    }
    case `${RESPONSES.UPDATE_RESPONSE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.updatingResponse = true
      break
    }
    case `${RESPONSES.UPDATE_RESPONSE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.updatingResponse = false
      break
    }
    case `${RESPONSES.SET_RESPONSES}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchingResponses = true
      break
    }
    case `${RESPONSES.SET_RESPONSES}_${PROMISE_STATUS.FULFILLED}`: {
      state.responses = action.payload
      state.loading.fetchingResponses = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
