import { PARTICIPANTS } from '@store/action-types'
import * as participantsApi from '@api/participants.api'

export const getParticipants = () => {
  return {
    type: PARTICIPANTS.SET_PARTICIPANTS,
    async payload() {
      return await participantsApi.getParticipants()
    },
  }
}

export const postAParticipant = (data) => {
  return {
    type: PARTICIPANTS.SET_A_PARTICIPANT,
    async payload() {
      return await participantsApi.postAParticipant(data)
    },
  }
}

export const updateAParticipant = (uuid, data) => {
  return {
    type: PARTICIPANTS.UPDATE_PARTICIPANTS,
    async payload() {
      return await participantsApi.putAParticipant(uuid, data)
    },
  }
}

export const patchAParticipant = (uuid, data) => {
  return {
    type: PARTICIPANTS.UPDATE_PARTICIPANTS,
    async payload() {
      return await participantsApi.patchAParticipant(uuid, data)
    },
  }
}

export const getParticipantsSurvey = (data) => {
  return {
    type: PARTICIPANTS.GET_PARTICIPANTS_SURVEY,
    async payload() {
      return await participantsApi.getParticipantsSurvey(data)
    },
  }
}
